import {Reducer, Action} from 'redux';

import * as types from '../actions/actionTypes';

import {SORT_NEWEST, GENDER_FILTER_BOYS, GENDER_FILTER_GIRLS} from '../components/gridwall/gridwallConstants';
import {FilterManager} from '../components/gridwall/filterManager';

import ALL_FILTERS from '../../content/filters/availableGridwallFilters';

const genderBoysGirls = [GENDER_FILTER_BOYS, GENDER_FILTER_GIRLS];

export interface GridwallState {
  products: {
    colorNumber: string;
    colors: string[];
    displayName: string;
    listPrice: string;
    numberOfColors: number;
    shipDate: string;
    styleNumber: string;
    uniformProgram: string;
    inspiration: string;
    bodyType?: string;
  }[];
  filterManager: FilterManager;
  routeKey: number;
  userFilters: {
    selectedFilters: string[];
    showHidden: boolean;
    sortDirection: string;
    customSearchParam: string;
    category?: string;
  };
}

const initialState: GridwallState = {
  products: [],
  filterManager: new FilterManager(ALL_FILTERS),
  routeKey: 0,
  userFilters: {
    selectedFilters: [],
    showHidden: false,
    sortDirection: '',
    customSearchParam: '',
  },
};

// TODO: These actions can be separate types later
interface GridwallAction extends Action {
  fieldKey: string;
  isSportVariance: boolean;
  items: GridwallState['products'];
  needByDate: string[];
  searchString: string;
  showHidden: boolean;
  sortKey: string;
  urlFilters: GridwallState['userFilters'];
}

// If the only portion of the URL that changes are the queryParams ("?select=hc_Bags"), the
// React Router won't consider it a new route. As such, we introduced a third Path Parameter
// that is always changed with each filter switch, allowing React to properly utilize each
// added filter as an "updated Route".
//    ~/gridwall/:gender/:sport/:routeKey

const generateRouteKey = (currentKey: number) => {
  let newKey = Math.floor(Math.random() * 1000) + 1;
  while (newKey === currentKey) {
    // ensures we don't use the same key twice in a row
    newKey = Math.floor(Math.random() * 1000) + 1;
  }
  return newKey;
};

const gridwallReducer: Reducer<GridwallState> = (
  state = initialState,
  incomingAction
) => {
  const action = incomingAction as unknown as GridwallAction;

  switch (action.type) {
    case types.LOAD_GRIDWALL_ITEMS:
      return {
        ...state,
        products: action.items
      };

    // This seems like a lot of work for a reducer to be doing, but it is the only known way to accomplish
    // what we need from the React Router to honor non-path-param route updates
    case types.TOGGLE_FILTER:
      let newSelectedFilters;

      if (state.userFilters.selectedFilters.includes(action.fieldKey)) {
        newSelectedFilters = [...state.userFilters.selectedFilters.filter(key => key !== action.fieldKey)];
      } else {
        newSelectedFilters = [...state.userFilters.selectedFilters, action.fieldKey];
      }

      let newState = {
        ...state,
        userFilters: {
          ...state.userFilters,
          selectedFilters: newSelectedFilters
        },
        routeKey: generateRouteKey(state.routeKey)
      };

      return newState;

    case types.TOGGLE_KIDS_FILTER: {
      if (!genderBoysGirls.includes(action.fieldKey)) {
        return { ...state };
      }

      let newSelectedFilters;

      if (state.userFilters.selectedFilters.includes(action.fieldKey)) {
        newSelectedFilters = [...state.userFilters.selectedFilters.filter(key => key !== action.fieldKey)];
      } else {
        newSelectedFilters = [...state.userFilters.selectedFilters, action.fieldKey];
      }
      
      // If it came from the sport landing page and
      // user deselects both genders, add them both back
      if (action.isSportVariance &&
          !newSelectedFilters.some(f => genderBoysGirls.includes(f))) {
        newSelectedFilters = [...newSelectedFilters, ...genderBoysGirls];
      }

      return {
        ...state,
        userFilters: {
          ...state.userFilters,
          selectedFilters: newSelectedFilters,
        },
      };
    }

    case types.SET_FILTERS_FROM_URL:
      return {
        ...state,
        userFilters: {
            ...action.urlFilters
        }
      };

    case types.CLEAR_FILTERS:
      return {
        ...state,
        routeKey: generateRouteKey(state.routeKey),
        userFilters: {
            sortDirection: SORT_NEWEST,
            showHidden: false,
            selectedFilters: [],
            customSearchParam: ""
        }
      };

    case types.SET_SORT:
      return {
        ...state,
        routeKey: generateRouteKey(state.routeKey),
        userFilters:
            {
              ...state.userFilters,
              sortDirection: action.sortKey
            }
      };

    case types.TOGGLE_SHOW_HIDDEN_FILTER:
      return {
        ...state,
        routeKey: generateRouteKey(state.routeKey),
        userFilters:
          {
            ...state.userFilters,
            showHidden: action.showHidden,
          }
      };

    case types.SET_NEED_BY_DATE:

      let filtersWithDateRemoved = state.userFilters.selectedFilters.slice().filter(f => !f.startsWith('shipDate'));

      if (action.needByDate.length > 0) {
        filtersWithDateRemoved.push(...action.needByDate);
      }

      let newVar = {
        ...state,
        routeKey: generateRouteKey(state.routeKey),
        userFilters: {
            ...state.userFilters,
            selectedFilters: filtersWithDateRemoved
        }
      };
      return newVar;

    case types.ADD_CUSTOM_SEARCH_PARAM:
      return {
        ...state,
        routeKey: generateRouteKey(state.routeKey),
        userFilters:
            {
              ...state.userFilters,
              customSearchParam: action.searchString
            }
      };

    case types.CLEAR_CUSTOM_SEARCH_PARAM:
      return {
        ...state,
        routeKey: generateRouteKey(state.routeKey),
        userFilters:
            {
              ...state.userFilters,
              customSearchParam: ''
            }
      };
    default:
      return state;
  }
}

export default gridwallReducer;
