import React from 'react';

import {GenderSportConfig} from '../gridwall/sportVariance/config';
import {NtsLazyImage} from "./ntsLazyImage/ntsLazyImage";
import {getAkamaiImageBaseUrl} from "../../api/config/ntsDownloadsBaseUrl";

interface Props {
  config: GenderSportConfig['banner'];
}

/**
 * Image only banner for landing pages
 * @param props
 * @constructor
 */
export const BannerImage: React.FC<Props> = (props) => {

  const positionClass = (props.config.desktop.copyPos === 'top') ? 'top' : 'bottom';
  const colorClass = (props.config.desktop.copyTheme === 'dark') ? 'dark' : 'light';

  const mobilePositionClass = (props.config.mobile.copyPos === 'top') ? 'top' : 'bottom';
  const mobileColorClass = (props.config.mobile.copyTheme === 'dark') ? 'dark' : 'light';

  return (
    <div className="nts-banner-image">

      <div className="hidden-sm hidden-md hidden-lg">
        <NtsLazyImage src={`${getAkamaiImageBaseUrl()}${props.config.mobile.imgSrc}`} alt={props.config.mobile.imgDesc} className={"nts-lazy-image"} />
        <div className={`nts-banner-copy-container ${mobilePositionClass} ${mobileColorClass}`}>
          <span className="title">{props.config.mobile.copyTitle}</span>
          <span className="desc hidden-xs">{props.config.mobile.copyDesc}</span>
        </div>
      </div>

      <div className="hidden-xs">
        <NtsLazyImage src={`${getAkamaiImageBaseUrl()}${props.config.desktop.imgSrc}`} alt={props.config.desktop.imgDesc} className={"nts-lazy-image"} />
        <div className={`nts-banner-copy-container ${positionClass} ${colorClass}`}>
          <span className="title">{props.config.desktop.copyTitle}</span>
          <span className="desc hidden-xs">{props.config.desktop.copyDesc}</span>
        </div>
      </div>

    </div>
  )
};
