export const TEAM_HOME_URL_PATH = "/en/en_US/nts/";
export const DEFAULT_TEAM_SPORTS_SOLD_TO = '';
export const DEFAULT_TEAM_SPORTS_COUPON_SOLD_TO = '';
export const NIKE_TYPE_FOOTWEAR = 'FOOTWEAR';

export const SPORT_LIST = [
  {name: "BASEBALL/FASTPITCH", value:"BASEBALL/FASTPITCH"},
  {name: "BASKETBALL", value:"BASKETBALL"},
  {name: "FOOTBALL", value:"FOOTBALL"},
  {name: "LACROSSE", value:"LACROSSE"},
  {name: "SOCCER", value:"SOCCER"},
  {name: "TENNIS", value:"TENNIS"},
  {name: "RUNNING", value:"TRACK & FIELD"},
  {name: "VOLLEYBALL", value:"VOLLEYBALL"},
  {name: "WRESTLING", value:"WRESTLING"}
];

export const SPORT_BASEBALL = "baseball";
export const SPORT_BASKETBALL = "basketball";
export const SPORT_FIELD_HOCKEY = "fieldhockey";
export const SPORT_FOOTBALL = "football";
export const SPORT_LACROSSE = "lacrosse";
export const SPORT_RUNNING = "running";
export const SPORT_SOCCER = "soccer";
export const SPORT_SOFTBALL = "softball";
export const SPORT_TENNIS = "tennis";
export const SPORT_TRAINING = "training";
export const SPORT_VOLLEYBALL = "volleyball";


export const FILTER_GROUP_CUSTOMIZATIONS = "customizations";
export const FILTER_GROUP_COLOR = "color";
export const FILTER_GROUP_SUSTAIN = "sustainablematerials";
export const FILTER_GROUP_UNIFORMS = "uniforms";
export const FILTER_GROUP_CLOTH_TOPS = "tops";
export const FILTER_GROUP_CLOTH_BOTS = "bottoms";

export const ORDER_STATUS_ID_OPEN_QUOTE = 20;
export const ORDER_STATUS_ID_OPEN = 2;

//--- Auth/User Constants
export const LS_ENABLE_TOKEN_REFRESH = 'nts_unite_enable_refresh';
// TODO: Add environment specific token to prevent cross-environment issues
//export const LS_ACCESS_TOKEN = `nts_access_token_${getEnvironment()}`;
export const LS_ACCESS_TOKEN = 'nts_access_token';
export const COOKIE_ACCESS_TOKEN = 'nts_access_token';
export const NTS_MAX_COOKIE_EXPIRY = 52560000;
export const AUTH_HEADER_PREFIX = "OIDC";

export const COOKIE_IMPERSONATED_USER_ID = 'impersonated_team_user_id';
export const COOKIE_IMPERSONATED_AS_ADMIN = 'impersonated_admin_status';

//--- Forms
export const MAX_LENGTH_CCEMAILS = 1024;
export const TEAM_TYPE_LIST = [
  {value: 'High School', label: 'High School'},
  {value: 'College', label: 'College'},
  {value: 'Club', label: 'Club'},
  {value: 'Recreational', label: 'Recreational'}
];
export const SHIPPING_OPTION_LIST = [
  {value: "", label: "Standard"},
  {value: "Overnight", label: "Overnight"},
  {value: "2nd Day", label: "2nd Day"},
  {value: "3 Day", label: "3 Day"}
];

export const SHIP_TO_TYPE_SELECTED = 'SELECTED';
export const SHIP_TO_TYPE_ONETIME = 'ENTERED';
export const EMAIL_ALLOWED_CHARS_REGEX = /([^a-zA-Z0-9 \-_.!?#'"]+)/g;
export const FONTNAME_ALLOWED_CHARS_REGEX = /([^a-zA-Z0-9 \-_,.&'\s]+)/g;
export const HEX_COLOR_ALLOWED_CHARS_REGEX = /([^#a-fA-F0-9\s]+)/g;
export const HEX_COLOR_PATTERN_REGEX = /^#[0-9a-fA-F]+$/;

//-- Screen Resolutions
export const SCREEN_SM_MAX = 992;
export const SCREEN_MD_MIN = 993;
export const SCREEN_MD_MAX = 1200;
export const SCREEN_LG_MIN = 1201;
export const SCREEN_LG_MAX = 1440;
export const SCREEN_XL_MIN = 1441;

//-- User Profile Views
export const PROFILE_VIEW_ACCOUNT = 'view_account';
export const PROFILE_VIEW_NOTIFICATIONS = 'view_notifications';

//-- Notification States
export const NOTIFICATION_ERROR = 'ERROR';
export const NOTIFICATION_SUCCESS = 'SUCCESS';
export const NOTIFICATION_CANCEL = 'CANCEL';
export const NOTIFICATION_MISMATCH = 'MISMATCH';

//-- Artwork Constants
export const ACCEPTABLE_LOGO_FILE_TYPES = [
    'image/png','image/svg+xml','image/jpeg'
];
export const MAX_FILE_UPLOAD_SIZE = 10485770; // 10MB LIMIT + a 10 byte buffer
export const DEFAULT_SCENE7_LOGO = 'ARTWORK-PLACEMENT';
export const IMAGE_UNAVAILABLE_NAME = 'SEARCH_002_A';
export const IMAGE_UNAVAILABLE_URL = 'https://images2.nike.net/is/image/DPILS/defaultImage=/SEARCH_002_A';
export const CUSTOM_IMAGE_ZOOM_FACTOR = '.7'; // 70% zoom factor for Embodee uniform images

//-- Gridwall Constants
export const GRID_ITEM_FLAG_SUSTAIN_BLENDS = 'SustainableBlends';
export const GRID_ITEM_FLAG_RECYCLED_MATERIALS = 'RecycledPolyester';

//-- Environment Constants
export const ENVIRONMENT_LOCAL = 'local';
export const ENVIRONMENT_DEV = 'dev';
export const ENVIRONMENT_QA = 'qa';
export const ENVIRONMENT_STG = 'stg';
export const ENVIRONMENT_UAT = 'uat';
export const ENVIRONMENT_PROD = 'prod';

//-- Order Constants
export const SAP_SALES_ORG = '1020'; // Pre-SEC: 1000
export const SAP_COMPANY_CODE = '1220'; // Pre-SEC: 1098