import {LockerItem, Set, Sport, LockerState, Filter, LockerImage} from '../../reducers/lockerReducer';
import {validateFillinPersonalizedStockDesign, verifyFillinBlankStockProduct} from '../../helpers/fillInUtils';
import {showCartSelectionModal} from '../../actions/cartSelectorActions';
import {BUILDER_URL_BASE, PDP_URL_BASE, PDP_DESIGN_URL_BASE} from '../gridwall/gridwallConstants';
import {uniqBy} from '../../helpers/typedArrayHelpers';
import {DesignSearchService} from '../../api/services/designSearchService';
import {getImageSrcByKeyFromProductGroup} from "../../helpers/checkoutHelpers";
import {CUSTOM_IMAGE_ZOOM_FACTOR} from "../../helpers/constants";
import {isJbaDesignId} from "../../helpers/designHelpers";

enum DimensionGroupName {
    SETS = 'Sets',
    SPORT_GENDER = 'Sport|Gender',
}

type SingleOrArray<T> = T | T[];

interface GetLockerProductGroup {
    api?: string;
    elementid: string;
    formattedRetailPrice: string;
    name?: string;
    product?: SingleOrArray<GetLockerProduct>;
    uid?: string;
    imageList?: LockerImage[];
}

interface GetLockerProduct {
    bodyType: string;
    colorNumber: string;
    displayName: string;
    styleNumber: string;
    formattedRetailPrice: string;
}

interface DimensionGroup {
    name: string;
    dimension: {
        dimensionValue?: SingleOrArray<{
            _id: string;
            name: string;
        }>;
    };
}

interface GetLockerResponse {
    response: {
        template: {
            lockerContainer: {
                products: {
                    productGroup: SingleOrArray<GetLockerProductGroup>;
                } | '';
                gridNavigation: {
                    dimensions: {
                        refinements: {
                            dimensionGroup: SingleOrArray<DimensionGroup>;
                        };
                    };
                    totalRecords: string;
                };
            };
        };
    };
}

interface DesignSearchResponse {
    error: boolean;
    data: {
        belongsToLocker: boolean;
        belongsToPastOrder: boolean;
        openCartOrder: {
            cartId: number;
        } | null;
    } | null;
}

interface DesignSearchData {
    belongsToLocker: boolean;
    belongsToPastOrder: boolean;
    cartId?: string;
}

// TODO: Add size param options (thumbnail vs large)?
export const buildSapImageUrl = (style: string, color: string) => {
    return 'https://images2.nike.net/is/image/DPILS/' +
        style + '-' + color +
        '-PV?hei=178&fmt=png&resMode=sharp2&defaultImage=eCommerceDefault';
};

interface ParseLockerItems {
    items: LockerItem[];
    totalRecords: number;
}

export const parseLockerItems = (raw: GetLockerResponse): ParseLockerItems => {
    const result: ParseLockerItems = {
        items: [],
        totalRecords: 0,
    };

    const {lockerContainer} = raw.response.template;
    if (lockerContainer.products === '') {
        return result;
    }

    const {productGroup} = lockerContainer.products;
    const productGroupArray = Array.isArray(productGroup)
        ? [...productGroup]
        : [productGroup];

    result.totalRecords = Number.parseInt(lockerContainer.gridNavigation.totalRecords);
    result.items = productGroupArray.map(pg => {
        let product = Array.isArray(pg.product)
            ? pg.product[0]
            : pg.product;
        const imageArray = getImageArray(product, pg);

        return {
            color: product?.colorNumber ? product.colorNumber : '',
            displayName: pg.name
                 ? pg.name
                 : (product ? product.displayName : ''),
            elementId: pg.elementid,
            formattedRetailPrice: pg.formattedRetailPrice,
            images: imageArray,
            imageUrl: imageArray[0].imageUrl,
            productType: pg.uid
                ? pg.uid.startsWith('UD')
                    ? 'jba'
                    : 'custom sap'
                : 'sap',
            style: product ? product.styleNumber : '',
            uniformDesignId: pg.uid || '',
            selected: false,
            isOrderable: product?.formattedRetailPrice !== 'NOT ORDERABLE',
        };
    });
    return result;
};

export const getImageArray = (product:any, prodGroup:any) => {
    const hasImages = prodGroup && prodGroup.images && prodGroup.images.image;
    const hasImageArray = hasImages && prodGroup.images.image.length > 0;
    if(isJbaDesignId(prodGroup.uid)) {
        return [
            { imageUrl: `${getImageSrcByKeyFromProductGroup(prodGroup, 'top-front')},${CUSTOM_IMAGE_ZOOM_FACTOR}`, imageLabel: 'top-front' },
            { imageUrl: `${getImageSrcByKeyFromProductGroup(prodGroup, 'bottom-front')},${CUSTOM_IMAGE_ZOOM_FACTOR}`, imageLabel: 'bottom-front' },
        ];
    } else if(hasImageArray) {
        return prodGroup.images.image;
    } else if(hasImages && Object.keys(prodGroup.images.image).length > 0) {
        return [prodGroup.images.image]
    } else if(prodGroup.uid && prodGroup.api) {
        return [{ imageUrl: prodGroup.api, imageLabel: 'Uniform' }]
    } else {
        return [{ imageUrl: buildSapImageUrl(product.styleNumber, product.colorNumber), imageLabel: 'Item' }]
    }
}

interface ParseFilters {
    sets: Set[],
    sports: Sport[],
}

export const parseFilters = (raw: GetLockerResponse): ParseFilters => {
    const {dimensionGroup} = raw.response.template.lockerContainer.gridNavigation.dimensions.refinements;
    const dimensionGroupArray = Array.isArray(dimensionGroup)
        ? dimensionGroup
        : [dimensionGroup];

    const result: ParseFilters = {
        sets: [],
        sports: [],
    };

    const sets = dimensionGroupArray.find(dg => dg.name === DimensionGroupName.SETS);
    if (sets && sets.dimension && sets.dimension.dimensionValue) {
        const dimensionValueArray = Array.isArray(sets.dimension.dimensionValue)
            ? sets.dimension.dimensionValue
            : [sets.dimension.dimensionValue];
        result.sets = dimensionValueArray.map(dv => {
            return {
                name: dv.name,
                elementId: dv._id,
            };
        });
    }

    const sports = dimensionGroupArray.find(dg => dg.name === DimensionGroupName.SPORT_GENDER);
    if (sports && sports.dimension && sports.dimension.dimensionValue) {
        const dimensionValueArray = Array.isArray(sports.dimension.dimensionValue)
            ? sports.dimension.dimensionValue
            : [sports.dimension.dimensionValue];
        result.sports = uniqBy(dimensionValueArray.map(dv => {
            return {
                display: dv.name.split('|')[0],
                value: dv._id.split('+')[0],
            };
        }), (a) => a.display);
    }
    return result;
};

export const addToCart = async (item: LockerItem) => {
    if (item.productType !== 'jba') {
        if (item.uniformDesignId !== '') {
            // custom sap product
            validateFillinPersonalizedStockDesign(item.uniformDesignId)
                .then((result) => {
                    showCartSelectionModal({
                        isFillIn: false,
                        isJba: false,
                        defaultName: '',
                        products: [{
                            selectedStyle: result.selectedStyle,
                            productDetails: result.productDetails,
                            design: result.design,
                            priceDetails: result.priceDetails
                        }],
                    });
                })
                .catch((e: unknown) => console.error('Error saving to cart', e));
        } else {
            // stock product
            verifyFillinBlankStockProduct(`${item.style}-${item.color}`)
                .then((result) => {
                    showCartSelectionModal({
                        isFillIn: false,
                        isJba: false,
                        defaultName: '',
                        products: [{
                            selectedStyle: result.selectedStyle,
                            productDetails: result.productDetails,
                            design: {},
                            priceDetails: {}
                        }],
                    });
                })
                .catch((e: unknown) => console.error('Error saving to cart', e));
        }
    } else {
        // jba product
        showCartSelectionModal({
            isFillIn: false,
            isJba: true,
            defaultName: '',
            products: [{
                name: item.displayName,
                uniformId: item.uniformDesignId,
            }],
        });
    }
};

/**
 * Call the design search service for a product
 */
export const checkDesignSearch = async (designId: string): Promise<DesignSearchData> => {
    const data = await DesignSearchService.designSearchById(designId)
        .catch(() => Promise.reject('Failed to fetch jba design data'));
    if (data) {
        const response = data as unknown as DesignSearchResponse;
        if (!response.error && response.data !== null) {
            return {
                belongsToLocker: response.data.belongsToLocker,
                belongsToPastOrder: response.data.belongsToPastOrder,
                cartId: response.data.openCartOrder !== null
                    ? `${response.data.openCartOrder.cartId}`
                    : undefined,
            };
        }
    }
    return {
        belongsToPastOrder: false,
        belongsToLocker: false,
    };
};

export const buildProductLocation = (item: LockerItem) => {
    switch (item.productType) {
        case 'jba':
            // jba product
            return `${BUILDER_URL_BASE}${item.uniformDesignId}`;
        case 'custom sap':
            // custom sap product
            return `${PDP_DESIGN_URL_BASE}${item.uniformDesignId}`;
        case 'sap':
            // stock product
            return `${PDP_URL_BASE}${item.style}`;
    }
}

export const buildPrintUrl = (email: string, elementIds: string[]) => {
    return `/en/en_US/p/print_locker?user_name=${email}&elements=${elementIds.join('+')}`;
};

export const buildFilters = (lockerState: LockerState): Filter[] => {
    // TODO: Uncomment to add back Sport filters to Locker page dropdown
    /*return [
        ...lockerState.sports.map(sp => ({
            label: sp.display,
            value: sp.value,
            isSet: false,
        })),
        ...lockerState.sets.map(s => ({
            label: s.name,
            value: s.elementId,
            isSet: true,
        })),
    ];*/
    return [
        ...lockerState.sets.map(s => ({
            label: s.name,
            value: s.elementId,
            isSet: true,
        })),
    ];
};