import {Reducer, Action} from 'redux';
import {cloneDeep} from 'lodash';

import * as types from '../actions/actionTypes';

type SingleOrArray<T> = T | T[];

export interface ProductGroup {
  api: string;
  imagesMap?: {
    entry: SingleOrArray<{ key: string; value: string; }>
  };
  product: SingleOrArray<{
    colorNumber: string;
    displayName: string;
    styleNumber: string;
    bodyType?: string;
  }>;
  uid: string;
}

export interface SapStockProduct {
  graphicDesignID?: string;
  name: string;
  productId: string;
  styleColor: string;
  crds?: [];
}

export interface SapCartData {
  stockProductList?: SapStockProduct[];
  shippingData?: ShippingData;
  totalRetailPrice?: number;
  totalWholesalePrice?: number;
  totalQuantity?: number;
}

export interface ShippingData {
  shipToId: string;
  addressType: string;
  address?: {
    name: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    stateProvince: string;
    postalCode: string;
    country: string;
  };
  oneTimeShipToAddress?: {
  };
  CartShippingCondition: string;
  CartShippingMethod: string;
}

export interface CheckoutState {
  addressForm: {
    errors: {};
  };
  disableSubmit: boolean;
  infoForm: {
    ccemails?: string;
    ccemailsList?: {
      value: string;
      error: boolean;
    }[];
    customerTeamName?: string;
    customerType?: string;
    customerCity?: string;
    customerState?: string;
    phoneNum?: string;
    poNum?: string;
    errors: {
      ccemails?: boolean;
      customerTeamName?: boolean;
      customerType?: boolean;
      customerCity?: boolean;
      customerState?: boolean;
      phoneNum?: boolean;
      poNum?: boolean;
    };
    formSubmitted?: boolean;
    teamTypes?: {
      value: string;
      label: string;
    }[];
  };
  isValidJbaAccount: boolean;
  isValidSapAccount: boolean;
  jbaCartData: {
    orderEntry?: {
      name: string;
      totalPrice: string;
      quoteDateTime: string;
    };
    orderContent?: {
      products: {
        productGroup: SingleOrArray<ProductGroup>;
      };
    };
    shippingData?: ShippingData;
  };
  jbaShipToData: {
    customerNumber?: string;
    customerName?: string;
    shipTos?: {
      customerNumber: string;
      contactInfo: {
        name: string;
      }[];
    }[];
  };
  sapCartData: SapCartData;
  shipToData: {
    customerNumber?: string;
    customerName?: string;
  };
  showShippingModal: boolean;
  submitAttempted: boolean;
}

// TODO: These actions can be separate types later
interface CheckoutAction extends Action {
  show: boolean;
  shipToData: CheckoutState['shipToData'];
  sapCartData: CheckoutState['sapCartData'];
  data: {
    cartType: 'jbaCartData' | 'sapCartData';
    shippingData: unknown;
    account: unknown;
  };
  form: CheckoutState['infoForm'];
  disable: boolean;
  submitAttempted: boolean;
  validationResults: {
    sapAccounValidationResult?: unknown;
    jbaAccountValidationResult?: unknown;
  };
  jbaCartData: {
    order: CheckoutState['jbaCartData'];
  }
}

const initialState: CheckoutState = {
  addressForm: { errors: {} },
  disableSubmit: false,
  infoForm: { errors: {} },
  isValidJbaAccount: false,
  isValidSapAccount: false,
  jbaCartData: {},
  jbaShipToData: {},
  sapCartData: {},
  shipToData: {},
  showShippingModal: false,
  submitAttempted: false,
};

const checkoutReducer: Reducer<CheckoutState> = (
  state = initialState,
  incomingAction,
) => {
  const action = incomingAction as unknown as CheckoutAction;

  switch (action.type) {
    case types.SHOW_SHIPPING_MODAL:
      return {
        ...state,
        showShippingModal: action.show
      };

    case types.LOAD_SHIP_TOS:
      return {
        ...state,
        shipToData: {
          ...action.shipToData,
        },
      };

    case types.LOAD_JBA_SHIP_TOS:
      return {
        ...state,
        jbaShipToData: action.shipToData
      };

    case types.LOAD_SAP_CART_DATA:
      return {
        ...state,
        sapCartData: action.sapCartData
      };

    case types.LOAD_JBA_CART_DATA:
      return {
        ...state,
        jbaCartData: Object.assign({}, state.jbaCartData, action.jbaCartData.order)
      };

    case types.UPDATE_SAP_CART_DATA:
      let sapCartData = cloneDeep(state.sapCartData);
      return {
        ...state,
        sapCartData: sapCartData
      };

    case types.UPDATE_BILLING_ACCOUNT:
      return {
        ...state,
        [action.data.cartType]: {
          ...state[action.data.cartType],
          billingData: action.data.account
        },
      };

    case types.UPDATE_SHIPPING_DATA:
      return {
        ...state,
        [action.data.cartType]: {
          ...state[action.data.cartType],
          shippingData: action.data.shippingData
        },
      };

    case types.UPDATE_SUBMIT_ATTEMPTED:
      return {
        ...state,
        submitAttempted: action.submitAttempted
      };
      
    case types.SET_CHECKOUT_ACCOUNT_VALIDATION:
      const isValidSap: boolean =
        action.validationResults &&
        !!action.validationResults.sapAccounValidationResult &&
        typeof action.validationResults.sapAccounValidationResult !== 'undefined';
      const isValidJba: boolean =
        action.validationResults &&
        !!action.validationResults.jbaAccountValidationResult &&
        typeof action.validationResults.jbaAccountValidationResult !== 'undefined';
      return {
        ...state,
        isValidJbaAccount: isValidJba,
        isValidSapAccount: isValidSap
      };
      
    case types.DISABLE_CHECKOUT_SUBMIT:
      return {
        ...state,
        disableSubmit: action.disable
      };

    case types.UPDATE_INFO_FORM:
      return {
        ...state,
        infoForm: action.form
      };

    default:
      return state;
  }
};

export default checkoutReducer;
