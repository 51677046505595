import * as types from "../actions/actionTypes";
import {store} from "../../App"
import {cookies} from "../api/services";
import {UserProfileService} from "../api/services/ntsWebappService";
import NtsIdentityService from "../api/services/ntsIdentityService";
import {
  COOKIE_IMPERSONATED_AS_ADMIN,
  COOKIE_IMPERSONATED_USER_ID,
  ENVIRONMENT_PROD,
  LS_ACCESS_TOKEN,
  COOKIE_ACCESS_TOKEN,
  TEAM_HOME_URL_PATH
} from "../helpers/constants";
import {showMessageModal} from "./messageModalActions";
import {enableLoginRefreshInterval} from "./authActions";
import {getCookieDomain, getDateObjectMinutesFromNow, mapUniteUserToNtsUser} from "../helpers/uniteHelpers";
import {isJwtTokenValid} from "../helpers/jwtHelpers";
import {setRetailExperienceType} from "../helpers/retailHelpers";
import {
  getImpersonatedUserId,
  isImpersonated,
  isImpersonatedAsAdmin,
  padStringLeadingZeros
} from "../helpers/userProfileHelpers";
import {getNavigationState} from "../helpers/navbarHelpers";
import {dispatchClearNavigationState, dispatchLoadNavigationState} from "./navigationActions";
import {setAnalyticsDimension} from "../helpers/googleHelpers";
import {asyncLocalStorage, getEnvironment} from "../helpers/browserHelpers";
import {authService} from "../../App";
import {getOidcLsKeyName} from "../../auth/utils/helpers";

// Domain for setting/retrieving cookies
const cookieDomain = getCookieDomain()

export function dispatchUserStateIsLoading(state) {
  return store.dispatch({ type: types.LOADING_USER_STATE, state: state });
}

/**
 * Updates redux (userProfile) with user details
 * @param userData
 * @returns {*}
 */
export function dispatchLoadUserProfile(userData) {
  return store.dispatch({ type: types.LOAD_USER_PROFILE, user: userData });
}

/**
 * Clears userProfile data from redux
 * @returns {{type: string}}
 */
export function dispatchClearUserProfile() {
  return store.dispatch({ type: types.CLEAR_USER_PROFILE });
}

export const setAuthCookies = (accessToken) => {

  const expiryTime = getDateObjectMinutesFromNow(1000);
  const envType = getEnvironment();

  if (envType === ENVIRONMENT_PROD) {
    cookies.set(COOKIE_ACCESS_TOKEN, accessToken, {domain: cookieDomain, path: '/', secure: true, expires: expiryTime});
  } else {
    cookies.set(COOKIE_ACCESS_TOKEN, accessToken, {domain: cookieDomain, path: '/', expires: expiryTime});
  }
}

/**
 * Manages process of obtaining user details and setting UserState in Redux
 * @param accessToken
 * @returns {Promise<void>}
 */
export const handleLogin = async (accessToken) => {
  await NtsIdentityService.syncUserDetails().catch((e) => console.error('Error syncing user details', e));
  let userResponse = await NtsIdentityService.getUserDetails().catch((e) => console.error('Error validating user details', e));
  if(userResponse && userResponse.data) {
    let userDetails = {...userResponse.data};
    if(isImpersonated()) {
      const impersonatedUser = await NtsIdentityService.getImpersonationDetails(getImpersonatedUserId()).catch((e) => console.error('Error validating impersonation details', e))
      if(impersonatedUser) {
        const mergedUser = applyUserImpersonationOverrides(userDetails, impersonatedUser);
        setUserInSession(transformIdentityUserObject(mergedUser), accessToken);
      } else {
        cookies.remove(COOKIE_IMPERSONATED_AS_ADMIN, {path: '/', domain: cookieDomain});
        cookies.remove(COOKIE_IMPERSONATED_USER_ID, {path: '/', domain: cookieDomain});
        showMessageModal({ header: "LOGIN ERROR", message: "Unable to restore impersonation session. Please try to log in again." });
      }
    } else {
      setUserInSession(transformIdentityUserObject(userDetails), accessToken);
    }
  } else {
    clearUserInSession();
    showMessageModal({ header: "LOGIN ERROR", message: "Unable to create session for user. Please try to log in again." });
  }
}

/**
 * Registers Unite user with NikeTeam services
 * @param user
 * @param accessToken
 * @returns {Promise<void>}
 */
export const handleRegistration = async (user, accessToken) => {
  await registerNtsUser(mapUniteUserToNtsUser(user)).catch((e) => {
    showMessageModal({ header: "REGISTRATION ERROR", message: "Unable to register user with Nike TEAM. Please try to log in again." });
  })
  await handleLogin(accessToken).catch(() => { console.error('Unable to login after registration') });
}

/**
 * Sets user in Redux, updates user experience settings for app
 * @param user
 */
export const setUserInSession = (user) => {
  enableLoginRefreshInterval(true);
  setRetailExperienceType(window.location.pathname);
  dispatchLoadUserProfile(user);
  setAnalyticsDimension('userid', user.profile.id)
  dispatchLoadNavigationState(getNavigationState(true, user.authorities))
}

/**
 * Removes user from memory (redux) and clears LS/Cookie flags
 */
const clearUserInSession = () => {

  // Clean up local storage
  localStorage.removeItem(LS_ACCESS_TOKEN);
  enableLoginRefreshInterval(false);

  // Clean up redux state
  dispatchClearUserProfile();
  dispatchClearNavigationState();

  // Clean up cookies (potentially) set by Identity Service
  cookies.remove(COOKIE_IMPERSONATED_AS_ADMIN, {path: '/', domain: cookieDomain});
  cookies.remove(COOKIE_IMPERSONATED_USER_ID, {path: '/', domain: cookieDomain});

  // LEGACY: Set nts_unite_accessToken cookie to 'N', notifies backend of logout attempt
  cookies.set(COOKIE_ACCESS_TOKEN, 'N', { domain: cookieDomain, path: '/' });

}

/**
 * Looks for user auth token, sets user in session if found
 * @returns {Promise<void>}
 */
export const checkForLoggedInUser = async () => {
  const accessToken = authService.getAccessToken();
  const hasToken = !!accessToken;
  const hasValidToken = hasToken && isJwtTokenValid(accessToken);
  if(hasToken && hasValidToken) {
    await handleLogin(accessToken).catch((e) => { console.error('Failed to restore user session') });
    dispatchUserStateIsLoading(false);
  } else {
    clearUserInSession();
    dispatchUserStateIsLoading(false);
  }

}

/**
 * Overrides admin user details & permissions with impersonated user details
 * @param origUser
 * @param impersonUser
 */
const applyUserImpersonationOverrides = (origUser, impersonUser) => {
  let mergedUser = {
    user: {...impersonUser},
    dealership: (origUser && origUser.dealership) ? {...origUser.dealership} : {},
    impersonatedUser: impersonUser
  };
  if(isImpersonatedAsAdmin()) {
    mergedUser.user.authorityList = origUser.user.authorityList;
  }
  return mergedUser;
}

/**
 * Converts Identity service user response to UserProfile object (redux)
 * @param rawUser
 * @param impersonUser (optional) - details of user being impersonated
 * @returns {{}}
 */
const transformIdentityUserObject = (rawUser) => {
  //TODO: Typescript interface for user object
  let userDetails = {};
  userDetails.isLoggedIn = true;
  userDetails.authorities = rawUser.user.authorityList;
  userDetails.profile = {...rawUser.user.upmProfile}
  userDetails.profile.teamUserId = rawUser.user.teamUserId;
  userDetails.accounts = {
    sapAccountNumber: rawUser.user.soldTo ? padStringLeadingZeros(rawUser.user.soldTo, 10) : '',
    sapCouponAccountNumber: rawUser.user.couponAccountNumber ? padStringLeadingZeros(rawUser.user.couponAccountNumber, 10) : '',
    jbaAccountNumber: rawUser.user.jbaAccountNumber ? rawUser.user.jbaAccountNumber : ''
  }
  userDetails.dealership = (rawUser && rawUser.dealership) ? {...rawUser.dealership} : {}
  userDetails.impersonatedUser = (rawUser.impersonatedUser) ? rawUser.impersonatedUser : {}
  return userDetails;
}

/**
 * Calls identity service to add a user to NTS (oracle) tables
 * allowing us to associate a user to our sites permissions/roles
 * @param user
 * @returns {Promise<T>}
 */
export function registerNtsUser(user) {
  return NtsIdentityService.registerNtsUser(user)
    .then(result => {
      return result;
    }).catch(error => {
      return error;
    });
}

/**
 * Calls Unite getCredentials to obtain fresh access_token
 * DOCS: https://confluence.nike.com/pages/viewpage.action?pageId=184680537#UniteWebSDK-DeveloperDocumentation-getCredential()
 */
export function renewUniteCredentials() {
  if(window.nike && window.nike.unite && window.nike.unite.session && window.nike.unite.session.getCredential) {
    recursivelyRenewUniteCredentials(3);
  } else {
    console.log('................MISSING NIKE UNITE SESSION................');
  }
}

/**
 * Retries getting user credentials from Unite before logging out user and showing login form
 * @param retries
 */
export function recursivelyRenewUniteCredentials(retries = 3) {
  if(retries > 0) {
    try {
      window.nike.unite.session.getCredential(
          (response) => {
            if(response && isJwtTokenValid(response.access_token)) {
              try {
                // TODO: Need to restore full user session? (setUserInSession())
                localStorage.setItem(LS_ACCESS_TOKEN, response.access_token);
                setAuthCookies(response.access_token);
              } catch(e) {
                setTimeout(() => {
                  recursivelyRenewUniteCredentials(retries - 1);
                }, 1000);
              }
            } else {
              setTimeout(() => {
                recursivelyRenewUniteCredentials(retries - 1);
              }, 1000);
            }
          },
          (error) => {
            setTimeout(() => {
              recursivelyRenewUniteCredentials(retries - 1);
            }, 1000);
          }
      );
    } catch (e) {
      setTimeout(() => {
        recursivelyRenewUniteCredentials(retries - 1);
      }, 1000);
    }
  } else {
    handleLogout();
  }
}

/**
 * Safely manages expired session, showing login form or error based on Unite availability
 */
export const handleLogout = () => {

  localStorage.removeItem(LS_ACCESS_TOKEN);
  localStorage.removeItem(getOidcLsKeyName());

  logout().then(() => {
    authService.signoutRedirect();
    window.location = TEAM_HOME_URL_PATH;
  }).catch(e => {
    console.log('Team logout incomplete but still performing OIDC logout');
    authService.signoutRedirect();
    window.location = TEAM_HOME_URL_PATH;
  });

};

/**
 * Clears out session data from redux, cookies, Unite and nts-webapp
 * @returns {Promise<any>}
 */
export function logout() {
  return new Promise((resolve, reject) => {

    clearUserInSession();

    return UserProfileService.logout().then((response) => {
      cookies.remove('bm_sv', {path: '/', domain: cookieDomain});
      resolve();
    }).catch(() => {
      resolve();
    });

  });
}
