import {LandingPageConfig} from "../sportVariance/config";

/**
 * Controls header (banner image & text) settings for each sport/gender combo
 */
export const promoPageConfig: LandingPageConfig = {
    mens: {
        baseball: {
            display: {
                title: "Men's Promo Baseball",
                gender: "Men's",
                sport: "Baseball",
            },
            banner: {
                desktop: {
                    style: "image-only",
                    imgSrc: "/sport_lp/desktop_mens_baseball.png",
                    imgDesc: "Mens Baseball Banner",
                    copyTitle: "Geared For Greatness",
                    copyDesc: "",
                    copyPos: "top",
                    copyTheme: "dark"
                },
                mobile: {
                    style: "image-only",
                    imgSrc: "/sport_lp/mobile_mens_baseball.png",
                    imgDesc: "Mens Baseball Banner",
                    copyTitle: "Geared For Greatness",
                    copyDesc: "",
                    copyPos: "bottom",
                    copyTheme: "light"
                }
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
        basketball: {
            display: {
                title: "Men's Promo Basketball",
                gender: "Men's",
                sport: "Basketball",
            },
            banner: {
                desktop: {
                    style: "image-only",
                    imgSrc: "/sport_lp/desktop_mens_basketball.png",
                    imgDesc: "Mens Basketball Banner",
                    copyTitle: "Next Generation of Hoops",
                    copyDesc: "",
                    copyPos: "top",
                    copyTheme: "dark"
                },
                mobile: {
                    style: "image-only",
                    imgSrc: "/sport_lp/mobile_mens_basketball.png",
                    imgDesc: "Mens Basketball Banner",
                    copyTitle: "Next Generation of Hoops",
                    copyDesc: "",
                    copyPos: "bottom",
                    copyTheme: "light"
                }
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
        football: {
            display: {
                title: "Men's Promo Football",
                gender: "Men's",
                sport: "Football",
            },
            banner: {
                desktop: {
                    style: "image-video",
                    imgSrc: "/sport_lp/mobile_mens_football.png",
                    imgDesc: "Mens Football Banner",
                    copyTitle: "Made For The Field",
                    copyDesc: "",
                    copyPos: "bottom",
                    copyTheme: "light",
                    videoSrc: "/sport_lp/Nike_StrongerThanOne_JoshJacobs_LongForm_1080p_16x9_IGTV_Captioned_English_Delivery_201123.mp4",
                    videoType: "video/mp4",
                    videoImgSrc: ""
                },
                mobile: {
                    style: "image-video",
                    imgSrc: "/sport_lp/mobile_mens_football.png",
                    imgDesc: "Mens Football Banner",
                    copyTitle: "Made For The Field",
                    copyDesc: "",
                    copyPos: "bottom",
                    copyTheme: "light",
                    videoSrc: "/sport_lp/Nike_StrongerThanOne_JoshJacobs_LongForm_1080p_16x9_IGTV_Captioned_English_Delivery_201123.mp4",
                    videoType: "video/mp4",
                    videoImgSrc: ""
                }
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
    },
    womens: {
        basketball: {
            display: {
                title: "Women's Promo Basketball",
                gender: "Women's",
                sport: "Basketball",
            },
            banner: {
                desktop: {
                    style: "image-only",
                    imgSrc: "/logos/niketeam_jocktag_full_banner.png",
                    imgDesc: "Womens Basketball Banner",
                    copyTitle: "",
                    copyDesc: "",
                    copyPos: "top",
                    copyTheme: "dark"
                },
                mobile: {
                    style: "image-only",
                    imgSrc: "/logos/niketeam_jocktag_full_banner.png",
                    imgDesc: "Womens Basketball Banner",
                    copyTitle: "",
                    copyDesc: "",
                    copyPos: "bottom",
                    copyTheme: "light"
                }
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
        softball: {
            display: {
                title: "Women's Promo Softball",
                gender: "Women's",
                sport: "Softball",
            },
            banner: {
                desktop: {
                    style: "image-only",
                    imgSrc: "/sport_lp/desktop_womens_softball.png",
                    imgDesc: "Womens Softball Banner",
                    copyTitle: "Geared For Greatness",
                    copyDesc: "",
                    copyPos: "top",
                    copyTheme: "light",
                },
                mobile: {
                    style: "image-only",
                    imgSrc: "/sport_lp/mobile_womens_softball.png",
                    imgDesc: "Womens Softball Banner",
                    copyTitle: "Geared For Greatness",
                    copyDesc: "",
                    copyPos: "bottom",
                    copyTheme: "light"
                }
            },
            nav: [],
            clearAll: {
                display: "Clear All",
                url: ``,
            },
        },
    },
};
