import * as React from 'react';

import {LockerImage} from '../../reducers/lockerReducer';
import {NtsImage} from "../ntsImage/NtsImage";

interface Props {
    images: LockerImage[];
    onSelect: (imageUrl: string) => void;
}

export const GridItemThumbs: React.FC<Props> = ({
    images,
    onSelect
}) => {
    const [visibleThumbIndex, setVisibleThumbIndex] = React.useState(0);
    const thumbnailCount = images.length;
    return (
        <div className="grid-item-thumbs" onMouseEnter={() => onSelect('')}>
            {thumbnailCount > 3 && visibleThumbIndex !== 0 &&
                <div className="grid-cell-thumb-toggle left" onClick={() => setVisibleThumbIndex(visibleThumbIndex - 3)}><i className="g72-arrow-thin-left" /></div>
            }
            {images.map((img, i) =>
                <NtsImage src={img.imageUrl}
                     alt="thumbnail"
                     key={i}
                     className={"grid-cell-color-option " + (i >= visibleThumbIndex && i < (visibleThumbIndex + 3) ? "showing" : '')}
                     title={img.imageLabel}
                     onMouseEnter={() => onSelect(img.imageUrl)}
                     onClick={() => onSelect(img.imageUrl)}
                />
            )}
            {thumbnailCount > 3 && visibleThumbIndex < (thumbnailCount - 3) &&
                <div className="grid-cell-thumb-toggle right" onClick={() => setVisibleThumbIndex(visibleThumbIndex + 3)}><i className="g72-arrow-thin-right" /></div>
            }
        </div>
    );
};
