import {fetchRequestXml, urlNtsEnvironment} from './index';
import {LS_ACCESS_TOKEN} from '../../helpers/constants';

export const PAGE_COUNT = 12;

export const LockerService = {
    getLocker: (start: number, setId?: string, sport?: string) => {
        let query = `count=${PAGE_COUNT}&lang_locale=en_US&type=locker&start=${start}`;
        if (setId) {
            query = query + `&setId=${setId}`;
        } else if (sport) {
            query = query + `&sgFilter=${sport}`;
        }
        const request = new Request(`${urlNtsEnvironment}/services/assortment/locker/get?${query}`, {
          method: 'GET',
          headers: {
            'Accept': 'application/xml',
            'Authorization': localStorage.getItem(LS_ACCESS_TOKEN) || '',
          }
        });
        return fetchRequestXml(request);
    },

    removeLockerItem: (elementId: string) => {
        const request = new Request(`${urlNtsEnvironment}/services/assortment/locker/remove?elements=${elementId}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/xml',
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN) || '',
            }
        });
        return fetchRequestXml(request);
    },

    createSet: (name: string, elementIds: string[]) => {
        const query = `lang_locale=en_US&name=${name}&elements=${elementIds.join('+')}`;
        const request = new Request(`${urlNtsEnvironment}/services/assortment/locker/set/add?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/xml',
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN) || '',
            }
        });
        return fetchRequestXml(request);
    },

    removeSet: (elementId: string) => {
        const query = `sets=${elementId}&lang_locale=en_US`;
        const request = new Request(`${urlNtsEnvironment}/services/assortment/locker/set/remove?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/xml',
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN) || '',
            }
        });
        return fetchRequestXml(request);
    },

    sendEmail: (email: string, fromEmail: string, toEmail: string, message: string, elementIds: string[]) => {
        const query = `mail=locker&from=${fromEmail}&to=${toEmail}&user_name=${email}&elements=${elementIds.join('+')}&message=${encodeURIComponent(message)}`;
        const request = new Request(`${urlNtsEnvironment}/en/en_US/p/mail?${query}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/xml',
                'Authorization': localStorage.getItem(LS_ACCESS_TOKEN) || '',
            }
        });
        return fetchRequestXml(request);
    },
};
