import {ProductImageUrlBuilder} from "../../helpers/productImageUrlBuilder";
import {PRODUCT_TYPES} from "./gridcell";
import embodeeBuilderBaseUrl from "../../api/config/embodeeBuilderBaseUrl";
import {EmbodeeService} from "../../api/services/embodeeService";
import {getEmbodeeGridwallImage} from "../../helpers/embodeeHelpers";
import {AppState} from "../../reducers";

const embodeeImageUrl = embodeeBuilderBaseUrl(window.location);

interface Inspiration {
  key: string;
  id: string;
}

/**
 * Return img src for main gridwall product images
 */
export const getGridImageUrl = (
  location: Location,
  style: string,
  color: string,
  imageType: string,
  view: string,
  productImages: string,
  uniformProgram: string,
) => {

  let imageUrl = '';
  if(uniformProgram === PRODUCT_TYPES.CUSTOMIZABLE) {
    if(imageType === 'grid') {
      imageUrl = `${embodeeImageUrl}/builder/ud/image/${style}/default/187,178,png,1,1`;
    } else {
      imageUrl = `${embodeeImageUrl}/builder/ud/image/${style}/default/45,45,png,1,1`;
    }
  } else {
    imageUrl = ProductImageUrlBuilder(location, style, color, imageType, view, productImages) || '';
  }
  return imageUrl;
};

/**
 * Returns image src specifically for JBA (DIGITAL/CUSTOM/DQT) products, both thumbnail and full size images
 */
export const getGridImageEmbodeeUrl = (inspiration: Inspiration, imageType: string, bodyType='top') => {
  bodyType = bodyType.toLowerCase()
  let zoom = bodyType === 'top' ? '1.0' : '0.8';
  let isDefault = inspiration.id === "";
  return getEmbodeeGridwallImage(isDefault ? inspiration.key : inspiration.id, imageType, bodyType, zoom);
};

/**
 * Retrieves set of inspirations for a given JBA (DIGITAL/CUSTOM/DQT) product
 */
export const getInspirationImageUrls = (styleNumber: string): Promise<Inspiration[]> => {
  return new Promise(resolve => {
    EmbodeeService.getInspirationsByStyle(styleNumber).then(function(response) {
      if(response && response.result && response.result.length > 0) {
        resolve(response.result);
      } else {
        resolve([]);
      }
    });
  });
};
