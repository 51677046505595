import {urlEmbodeeServices} from "../api/services";

/**
 * Obtain Embodee single garment view image URL
 *  Format: <host>/builder/ud/image/<UD number>/<UD type>/<width>,<height>,<format>,<view>,<camera preset>,[<debug mode>,<styletype>,<zoom factor>]
 *  https://confluence.nike.com/pages/viewpage.action?pageId=1160278331
 * @param styleNum = uniformId (UD6759eff454081) | styleCode (845914) | inspiration (DN4144:Inspiration1)
 * @param udType = uniform | default | single (inspiration)
 * @param width = max of 400px
 * @param height = max of 400px
 * @param format = png | jpg
 * @param view = 1 (front) | 2 (front-right) | 3 (right) | 4 (back-right) | 5 (back) | 6 (back-left) | 7 (left) | 8 (front-left)
 * @param camera = gridwall | render | single
 * @param debug = 0 | 1
 * @param styleOption = top | bottom
 * @param zoom = default 1.0 (float)
 */
export const getEmbodeeSingleGarmentView = (
    styleNum = '',
    udType = 'default',
    width = '200',
    height = '200',
    format = 'jpg',
    view = '1',
    camera = 'single',
    debug = '0',
    styleOption = 'top',
    zoom = '.8'
) => {
    return `${urlEmbodeeServices}builder/ud/image/${styleNum}/${udType}/${width},${height},${format},${view},${camera},${debug},${styleOption},${zoom}`;
}

/**
 * Helper for obtaining Embodee gridwall image URLs
 * @param styleNum = uniformId (UD6759eff454081) | styleCode (845914) | inspiration (DN4144:Inspiration1)
 * @param sizeType = grid (207x207) | thumb (45x45)
 * @param styleOption = top | bottom
 * @param zoom = default 1.0 (float)
 */
export const getEmbodeeGridwallImage = (styleNum: string, sizeType = 'grid', styleOption = 'top', zoom: string) => {
    let size = sizeType === 'grid' ? '207' : '45';
    let udType = styleNum.includes(':Inspiration') ? 'single' : 'default';
    return getEmbodeeSingleGarmentView(styleNum, udType, size, size, 'png', '1', 'gridwall', '0', styleOption, zoom)
}

/**
 * Helper for obtaining Embodee fill-in error image URLs
 * @param ud = uniformId (UD6759eff454081)
 * @param bodyType = top | bottom
 */
export const getEmbodeeFillInImage = (ud: string, bodyType='top') => {
    let zoom = bodyType === 'top' ? '1.0' : '0.8';
    return getEmbodeeSingleGarmentView(ud, 'default', '200', '200', 'png', '1', 'gridwall', '0', bodyType, zoom)
}
