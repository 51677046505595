import {calculateEndpointType} from './common';

/**
 * Provides base URL for calling embodee services and requesting images/inspirations (i.e. on the Gridwall)
 *
 * OLD? --- http://nikestaging.embodee.com/
 */
const endpointTypeToUrl = {
  local: 'https://niketeam-embodee-preprod.nike.net/',
  dev: 'https://niketeam-embodee-preprod.nike.net/',
  qa: 'https://niketeam-embodee-preprod.nike.net/',
  stg: 'https://niketeam-embodee-preprod.nike.net/',
  uat: 'https://niketeam-embodee-preprod.nike.net/',
  prod: 'https://builder.nike.com/'
};

export default function embodeeBuilderBaseUrl(location) {
  const type = calculateEndpointType(location, 'prod');
  return endpointTypeToUrl[type];
}
