import * as React from "react";
import {RouteComponentProps} from "react-router-dom";

// components
import {Header} from "../sportVariance/header";
import GridCell from "../gridcell";

// services & helpers
import {ProductService} from "../../../api/services/productSearchService";
import {capitalizeFirstLetter} from "../../../helpers/textValidationHelpers";
import {endFullLoadingSpinner, startFullLoadingSpinner} from "../../../actions/loadingSpinnerActions";
import {promoPageConfig} from "./config";
import {GenderSportConfig} from "../sportVariance/config";

// types
type RouteProps = RouteComponentProps<{
    sport: string;
    gender: string;
}>

interface Banner {
    source: string;
    title: string;
    text: string;
}

interface PageProps {
    banner: Banner;
}

type Props = PageProps & RouteProps;

interface State {
    sport: string;
    gender: string;
    config: GenderSportConfig | null;
    items: [],
    error: string,
    isLoading: boolean
}

export class PromoLandingPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        const sport = this.props.match.params.sport.toLowerCase();
        const gender = this.props.match.params.gender.toLowerCase();
        const pageConfig = this.getPageConfig(gender, sport);
        if(!pageConfig) {
            window.location.href = window.location.protocol + '//' + window.location.host + "/en/en_US/nts/";
        }
        this.state = {
            sport: sport,
            gender: gender,
            config: pageConfig,
            items: [],
            error: '',
            isLoading: true
        }
    }

    componentDidMount() {
        this.fetchItems().catch(() => {
            this.setState({...this.state, error: 'Unable to retrieve items. Please try again.', items: [], isLoading: false });
            endFullLoadingSpinner();
        });
    }

    getPageConfig(gender:string = "", sport:string = "") {
        if (!promoPageConfig ||
            !promoPageConfig[gender] ||
            !promoPageConfig[gender][sport]) {
            return null;
        }
        return {
            ...promoPageConfig[gender][sport]
        };
    }

    async fetchItems() {
        const sportQueryParam = `sport_${capitalizeFirstLetter(this.state.sport.replace("%20", "_"))}`;
        const genderQueryParam = `gender_${capitalizeFirstLetter(this.state.gender.slice(0, -1))}`;

        startFullLoadingSpinner()

        const data = await ProductService.getPromoItems(sportQueryParam, genderQueryParam)
            .catch(() => {
                this.setState({...this.state, error: 'Unable to retrieve items. Please try again.', items: [], isLoading: false });
                endFullLoadingSpinner();
            });

        if (data && data.length > 0) {
            this.setState({...this.state, error: '', items: data, isLoading: false});
        } else {
            this.setState({...this.state, error: '', items: [], isLoading: false });
        }

        endFullLoadingSpinner();
    }

    render() {

        return (
            <div className="container nts-promo-landing-page">

                <div className="nts-header-container">
                    <div className="nts-header-nav">
                        {this.state.config &&
                            <Header
                                isSportVariance={true}
                                config={this.state.config}
                                category={''}
                            />
                        }
                    </div>
                </div>

                <div className="grid-results-container">
                    <div className="grid-cells-container">
                        {!this.state.isLoading && this.state.items.length > 0 && this.state.items.map((item, i) => (
                            <GridCell key={i} item={item} isLoggedIn={true} />
                        ))}
                        {!this.state.isLoading && !this.state.error && this.state.items.length === 0 &&
                            <div className="grid-message">
                                <h4>No results found</h4>
                                <p>There are currently no promo items available for this sport</p>
                            </div>
                        }
                        {!this.state.isLoading && this.state.error && <div className="grid-message error">{this.state.error}</div>}
                    </div>
                </div>

            </div>
        );
    }
}
