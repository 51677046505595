import React from 'react';
import { Row, Col } from 'react-bootstrap';

import {GenderSportConfig} from './config';
import { BannerImage } from '../../shared/bannerImage';
import {BannerVideo} from "../../shared/bannerVideo";
import {BannerImageVideo} from "../../shared/bannerImageVideo";

interface Props {
  isSportVariance: boolean;
  category: string;
  config: GenderSportConfig;
}

export class Header extends React.Component<Props> {

  shouldComponentUpdate(nextProps: Props) {
      return (nextProps.config && this.props.config &&
        nextProps.config.display && this.props.config.display &&
        nextProps.config.display.title !== this.props.config.display.title) ||
        nextProps.category !== this.props.category ||
        nextProps.isSportVariance !== this.props.isSportVariance;
  }

  render() {
    if (this.props.isSportVariance && this.props.config) {
      return (
        <Row className="nts-header-container">
          <Col xs={12}>
            <div className="nts-header-nav">
              <h3>{this.props.config.display.title}</h3>
              <ul>
                {this.props.config.nav.map((item, i) =>
                  <li key={i}>
                    <a
                      href={item.url}
                      className={item.category === this.props.category ? 'selected' : undefined}
                    >
                        {item.display}
                    </a>
                  </li>
                )}
              </ul>
            </div>

            {this.props.config.banner.desktop.style === "image-only" && <BannerImage config={this.props.config.banner} />}
            {this.props.config.banner.desktop.style === "video-only" && <BannerVideo config={this.props.config.banner} />}
            {this.props.config.banner.desktop.style === "image-video" && <BannerImageVideo config={this.props.config.banner} />}

          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }
}
