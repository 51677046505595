import React from 'react';

import {GenderSportConfig} from '../gridwall/sportVariance/config';

interface Props {
  config: GenderSportConfig['banner'];
}

/**
 * Video only banner for landing pages
 * @param props
 * @constructor
 */
export const BannerVideo: React.FC<Props> = (props) => {

  return (
    <div className="nts-banner-video">

      <div className="hidden-sm hidden-md hidden-lg">
        <div>
          <video>
            <source src={props.config.desktop.videoSrc} type={props.config.desktop.videoType} />
          </video>
        </div>
      </div>

      <div className="hidden-xs">
        <div>
          <video>
            <source src={props.config.desktop.videoSrc} type={props.config.desktop.videoType} />
          </video>
        </div>
      </div>

    </div>
  );
};
