import * as React from "react";
import {IMAGE_UNAVAILABLE_URL} from "../../helpers/constants";

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    fallback?: string;
}

/**
 * Extends HTML <img> element with fallback attribute
 * @param fallback - URL of fallback image
 * @param props
 * @constructor
 */
export const NtsImage: React.FC<Props> = ({ fallback, ...props }) => {

    return (
        <img {...props}
             onError={({currentTarget}) => {
                 currentTarget.onerror = null; // prevents looping
                 currentTarget.src = (fallback) ? fallback : IMAGE_UNAVAILABLE_URL;
             }}
        />
    );

};