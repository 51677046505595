import {isWholesalePricing} from './orderHelpers';
import {IMAGE_UNAVAILABLE_URL} from "./constants";

export const DATE_SLASH_FORMAT = 'slashes';
export const DATE_DASH_FORMAT = 'dashes';
export const DATE_TIME_AMPM_FORMAT = '12';
export const DATE_TIME_24HR_FORMAT = '24';

/**
 * Converts NTS-WEBAPP.AddressObject to SB style address
 * @param account
 * @returns {{addresseeLine: string, streetName: string, city: string, provinceStateCode: string, postalCode: string, countryCode: string}}
 */
export function mapJbaAccountToAddress(account) {

  return {
    addresseeLine: account.name,
    streetName: `${account.line1}, ${account.line2}, ${account.line3}, ${account.line4}`,
    city: account.city || '',
    provinceStateCode: account.state || '',
    postalCode: (account.zip2) ? `${account.zip1}-${account.zip2}` : `${account.zip1}`,
    countryCode: account.country || ''
  };

}

/**
 * Provides address wrapper for Sold To account name
 * @param account
 * @returns {{addresseeLine: string, streetName: string, city: string, provinceStateCode: string, postalCode: string, countryCode: string}}
 */
export function mapSapAccountToAddress(account) {

  return {
    addresseeLine: account.customerName,
    streetName: '',
    city: '',
    provinceStateCode: '',
    postalCode: '',
    countryCode: ''
  }

}

/**
 * Handles ship date value and formatting for display
 * @param quoteDtStr
 * @param isCustom
 * @returns {string}
 */
export function getSapStockShipDate(quoteDtStr, isCustom) {
  // blank stock = +10 day lead time || custom stock = +21 day lead time
  const daysOut = (isCustom) ? 22 : 11; // +1 to start count after order date
  const shipDate = getFutureDateByDays(quoteDtStr, daysOut);
  return getLongFormStringDate(shipDate, false);
}

/**
 * Converts mm/dd/yyyy to yyyy-mm-dd format
 * @param slashDate
 * @returns {string}
 */
export function convertSlashDateToDashDate(slashDate) {
  const dateArray = slashDate.split('/');
  return dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1];
}

/**
 * Returns todays date in string format
 * Format options:
 *  (slashes) mm/dd/yyyy
 *  (dashes) yyyy-mm-dd
 * @returns {string}
 */
export function getDateAsString(dateObj, format = 'slashes') {
  let dateStr;
  let dd = dateObj.getDate();
  let mm = dateObj.getMonth() + 1; // January is 0
  const yyyy = dateObj.getFullYear();
  if (dd < 10) {
    dd = '0' + dd
  } // Prefix 0 if single digit
  if (mm < 10) {
    mm = '0' + mm
  } // Prefix 0 if single digit
  if(format === 'dashes') {
    dateStr = `${yyyy}-${mm}-${dd}`
  } else {
    dateStr = `${mm}/${dd}/${yyyy}`
  }
  return dateStr;
}

/**
 * Returns given ISO Date string in string format ( ignore timezone )
 * @param dateStr - ISO Datetime string '2023-05-04T21:39:38.000Z'
 * @returns {string} '2023-05-04 09:39 PM'
 */
export const formatIsoDateString = (dateStr) => {
  const [date, time] = dateStr.split('T');
  const [hour, minute, second] = time.split('.')[0].split(':');
  const ampm = hour >= 12 ?  'PM' :  'AM';
  const twelveHoursFormat = ((Number(hour)+11)%12+1).toString().padStart(2, 0)+ ':' + minute.padStart(2, 0) + ' ' + ampm;
  return `${date} ${twelveHoursFormat}`
}

/**
 * Returns given date in string format (includes day, month, year and hours)
 * @param dateObj - Javascript Date() Object
 * @param dateFormat - Format options
 *    (slashes) mm/dd/yyyy
 *    (dashes) yyyy-mm-dd
 * @param timeFormat - 12 (am/pm) or 24 hour format
 */
export const getDateTimeAsString = (dateObj, dateFormat, timeFormat) => {
  const strDate = getDateAsString(dateObj, dateFormat);
  let strTime = (timeFormat === DATE_TIME_AMPM_FORMAT) ?
        dateObj.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit'}) :
      `${dateObj.getHours()} : ${dateObj.getMinutes()}`;
  return `${strDate} ${strTime}`;
}

/**
 * Returns date in mm/dd/yyyy format with x days added
 * @param dateStr
 * @param days
 * @returns {string}
 */
export function getFutureDateByDays(dateStr, days) {
  let calculatedDate = new Date(dateStr);
  calculatedDate.setDate(calculatedDate.getDate() + days);
  return getDateAsString(calculatedDate);
}

/**
 * Returns string date in long format (i.e. January 01, 2099)
 * @param dateStr
 * @param isUTC - Notes whether dateStr already has timezone info (i.e. T00:00:00)
 * @returns {string}
 */
export function getLongFormStringDate(dateStr, isUTC) {

  if (!isUTC) {
    dateStr = convertSlashDateToDashDate(dateStr);
    dateStr += 'T00:00:00';
  }

  const date = new Date(dateStr); // force LOCAL time, without the T00:00:00 the Date would be UTC and Western hemisphere dates will be a day out
  const options = {year: 'numeric', month: 'long', day: 'numeric'};
  return date.toLocaleString('en-US', options);
}

export function getSapStockImageUrl(styleColor) {
  //return 'https://images2.nike.net/is/image/DPILS/' + styleColor + '-PV?wid=80&amp;hei=120&amp;fmt=png-alpha&amp;qlt=90,0&amp;resMode=sharp&amp;op_usm=0.5,0.3,0,0&amp;defaultImage=/SEARCH_002_A';
  //return 'https://images2.nike.net/is/image/DPILS/' + styleColor + '-PHSFH000?fmt=jpeg&resMode=sharp2&defaultImage=683333-546-PV&wid=80&hei=120';
  //return 'https://images2.nike.net/is/image/DPILS/' + styleColor + '-PHSFH000?fmt=jpeg&resMode=sharp2&defaultImage=defaultImage=/SEARCH_002_A';
  //return 'https://images2.nike.net/is/image/DPILS/' + styleColor + '-PV?wid=80&hei=120&fmt=png-alpha&qlt=90,0&resMode=sharp&op_usm=0.5,0.3,0,0&defaultImage=/SEARCH_002_A';
  return 'https://images2.nike.net/is/image/DPILS/' + styleColor + '-PV?wid=160&hei=240&fmt=png-alpha&qlt=90,0&resMode=sharp&op_usm=0.5,0.3,0,0&defaultImage=/SEARCH_002_A';
}

export function getSapProductQuantity(product) {
  const hasCrds =
    product &&
    product.crds &&
    product.crds.length > 0;

  return (hasCrds) ? product.crds[0].plannedQuantity : 0;
}

export function getSapTotalPrice(product) {
  const totalQty = getSapProductQuantity(product);
  let baseWholesalePrice;
  if(isWholesalePricing()) {
    baseWholesalePrice = getSapProductWholesalePrice(product);
  } else {
    baseWholesalePrice = getSapProductRetailPrice(product);
  }
  const totalPrice = totalQty * +baseWholesalePrice;
  return totalPrice.toLocaleString("en-US", {style: "currency", currency: "USD"});
}

export function getSapProductWholesalePrice(product) {
  if (product && product.pricingDetails) {
    if (product.pricingDetails.wholeSaleFullEmbellishmentPrice) {
      return +product.pricingDetails.wholeSaleFullEmbellishmentPrice / 100;
    } else {
      return +product.pricingDetails.wholeSale / 100;
    }
  }
}

export function getQuoteSapTotalPrice(product) {
  const totalQty = getSapProductQuantity(product);
  const baseWholesalePrice = getSapProductRetailPrice(product);
  const totalPrice = totalQty ? totalQty * +baseWholesalePrice : +baseWholesalePrice;
  return totalPrice.toLocaleString("en-US", {style: "currency", currency: "USD"});
}

export function getSapProductRetailPrice(product) {
  if (product && product.pricingDetails) {
    if (product.graphicDesignID && product.pricingDetails.retailFullEmbellishmentPrice) {
      return +product.pricingDetails.retailFullEmbellishmentPrice / 100;
    } else {
      return +product.pricingDetails.retail / 100;
    }
  }
}

export function getSapCustomStockImageUrl(uniformDetails, product) {
  if (uniformDetails.templates.Front && uniformDetails.templates.Front.imageUrl) {
    return uniformDetails.templates.Front.imageUrl;
  } else if (uniformDetails.templates.Back && uniformDetails.templates.Back.imageUrl) {
    return uniformDetails.templates.Back.imageUrl;
  } else if (uniformDetails.templates.ReversibleFront && uniformDetails.templates.ReversibleFront.imageUrl) {
    return uniformDetails.templates.ReversibleFront.imageUrl;
  } else if (uniformDetails.templates.ReversibleBack && uniformDetails.templates.ReversibleBack.imageUrl) {
    return uniformDetails.templates.ReversibleBack.imageUrl;
  } else {
    return getSapStockImageUrl(product.styleColor);
    // return this.getStockImageUrl();
  }

}

export function getJbaShipDate(product) {

  const hasShipDate =
    product &&
    product.shipDate !== '';

  if (hasShipDate && product.shipDate !== undefined) {
    //Remove the Z or the date gets modified from midnight zulu to the local time date equivalent.
    //This way it matches the dates that have been shown in cart all along.
    return getLongFormStringDate(product.shipDate.replace('Z', ''), true);
  } else {
    return "NOT AVAILABLE";
  }

}

export function getJbaTotalQuantity(product) {
  if(product && product.sizeQuantityPrice) {
    const isArraySizes = Array.isArray(product.sizeQuantityPrice);
    if (!isArraySizes) {
      product.sizeQuantityPrice = [product.sizeQuantityPrice];
    }
    let qty = 0;
    product.sizeQuantityPrice.map(size => {
      if(size && size.quantity) qty += +size.quantity;
      return size;
    });
    return qty;
  } else {
    return 0;
  }
}

export function getJbaTotalPrice(product, priceType) {
  const totalQty = getJbaTotalQuantity(product);
  const basePrice = product[priceType];
  const totalPrice = totalQty ? totalQty * +basePrice : +basePrice;
  return totalPrice.toLocaleString("en-US", {style: "currency", currency: "USD"});
}

/**
 * From a stringified list, remove the given entry if match is found in the list
 * @param userEmail
 * @param emailListStr
 * @param separator
 * @returns
 */
export const removeUserEmailFromList = (userEmail, emailListStr, separator) => {
  return emailListStr.split(separator).filter(email => email !== userEmail).join(',');
};

// Obtains custom image URL by key from product group
export const getImageSrcByKeyFromProductGroup = (productGroup, key = 'top-front') => {
  let url;
  const hasImages = productGroup && productGroup.imagesMap && productGroup.imagesMap.entry && Object.keys(productGroup.imagesMap.entry).length > 0;
  if(hasImages) {
    productGroup.imagesMap.entry.map(img => {
      if (img.key === key.toLowerCase()) {
        url = img.value;
      }
    });
  } else {
    url = IMAGE_UNAVAILABLE_URL; // fallback image
  }
  return url;
}
